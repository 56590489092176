import 'ol/ol.css';
import 'ol-ext/dist/ol-ext.css';
import { Feature, Map, View } from 'ol';
import { fromLonLat, transform, get as getProjection } from 'ol/proj';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer'
import { Circle as CircleStyle, Fill, Icon, Stroke, Style } from 'ol/style';
import { Point, LineString, Polygon } from 'ol/geom';
import { Draw, Modify, Select, Snap } from 'ol/interaction';
import { GeoJSON, WFS, GML } from 'ol/format';
import { equalTo as equalToFilter } from 'ol/format/filter';
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';
import LayerSwitcherImage from './ol-ext/LayerSwitcherImage';
import XYZ from 'ol/source/XYZ';
import CryptoJS from 'crypto-js';

import './style.css';
import config from './config';
import marker from './marker.png';

const key = '59b6ab46d379b89d794c87b74a511fbd59b6ab46d379b89d794c87b74a511fbd';
const iv = '0aaff094b6dc29742cc98a4bac8bc8f9';

let auth = null;

$.ajax({
  type: 'POST',
  url: 'search/getToken.php',
  success: function (data) {
    if (data !== "jqFziUMEgHkVB/tRluiMHw==") {
      if (getParameterByName(config.projectURLparam) === undefined || getParameterByName(config.projectURLparam) === null || getParameterByName(config.projectURLparam) === '')
        alert('Δεν έχει οριστεί το έργο...')
      else {
        const decrypted = CryptoJS.AES.decrypt(data, CryptoJS.enc.Hex.parse(key), { iv: CryptoJS.enc.Hex.parse(iv) });
        auth = decrypted.toString(CryptoJS.enc.Utf8);
        $('#ac-wrapper').css('display', 'none');
        getProject(true);
      }
    };
  }
});

$('#login-btn').on('click', (e) => {
  if (getParameterByName(config.projectURLparam) === undefined || getParameterByName(config.projectURLparam) === null || getParameterByName(config.projectURLparam) === '')
    alert('Δεν έχει οριστεί το έργο...')
  else {
    auth = $('#username').val() + ":" + $('#password').val();
    $('#ac-wrapper').css('display', 'none');

    $.ajax({
      type: 'POST',
      url: 'search/proxy.php',
      data: {username: $('#username').val(), password: $('#password').val()},
      success: function(data) {
        console.log(data);
      }
    });

    getProject(true);
  }
})

// Get project id 
const getParameterByName = (name) => {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
    results = regex.exec(location.search);
  return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

// Set EPGS:2100
proj4.defs("EPSG:2100", "+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9996 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=-199.87,74.79,246.62,0,0,0,0 +units=m +no_defs");
register(proj4);
getProjection('EPSG:2100').setExtent([104022.946289, 3850785.500488, 1007956.563293, 4624047.765686]);


// Basemap layer
const basemap1 = new TileLayer({
  source: new XYZ({
    url: 'https://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}'
  }),
  baseLayer: true,
  visible: true
});
const basemap2 = new TileLayer({
  source: new XYZ({
    url: 'https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}'
  }),
  baseLayer: true,
  visible: false
});
const basemap3 = new TileLayer({
  source: new XYZ({
    url: 'https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}'
  }),
  baseLayer: true,
  visible: false
});


// Address layer
const sourceAddress = new VectorSource();
const vectorAddress = new VectorLayer({
  source: sourceAddress,
  style: new Style({
    image: new Icon({
      anchor: [0.5, 46],
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixels',
      src: marker,
    }),
  }),
});


// Draw layer
const source = new VectorSource();
const vector = new VectorLayer({
  source: source,
  style: new Style({
    fill: new Fill({
      color: 'rgba(224, 130, 131, 0.5)',
    }),
    stroke: new Stroke({
      color: 'red',
      width: 2,
    }),
    image: new CircleStyle({
      radius: 7,
      fill: new Fill({
        color: 'red',
      }),
    }),
  }),
});

const map = new Map({
  target: 'map',
  layers: [basemap1, basemap2, basemap3, vectorAddress, vector],
  view: new View({
    center: fromLonLat([config.lng, config.lat]),
    zoom: config.zoom,
    minZoom: config.minZoom,
    maxZoom: config.maxZoom,
    extent: [...fromLonLat([config.extent[0], config.extent[1]]), ...fromLonLat([config.extent[2], config.extent[3]])],
  })
});
map.addControl(new LayerSwitcherImage());


// Search address
const input = document.getElementById("address-input");
const autocomplete = new google.maps.places.Autocomplete(input, config.googleOptions);
autocomplete.addListener("place_changed", () => {
  const place = autocomplete.getPlace();
  const location = place.geometry.location;
  map.getView().setCenter(fromLonLat([location.lng(), location.lat()]));
  map.getView().setZoom(18);
  const marker = new Feature({
    geometry: new Point(fromLonLat([location.lng(), location.lat()])),
  });
  sourceAddress.clear();
  sourceAddress.addFeature(marker);
})
$(document).ready(function () {
  $('input.deletable').wrap('<span class="deleteicon" />').after($('<span/>').click(function () {
    sourceAddress.clear();
    $(this).prev('input').val('').trigger('change').focus();
  }));
  $("#close").click(function () {
    $("#search").slideToggle("slow");
  });
});


// Get papyros layer features
// generate a GetFeature request
const featureRequest = new WFS().writeGetFeature({
  featureNS: 'https://papyros.gisprojects.crete.gov.gr',
  srsName: 'EPSG:3857',
  featureTypes: ['projects'],
  outputFormat: 'application/json',
  filter: equalToFilter('projectid', getParameterByName(config.projectURLparam))
});

// then post the request and add the received features to a layer
const getProject = (type) => {
  const headers = new Headers();
  headers.append('Authorization', 'Basic ' + btoa(auth));

  fetch('https://gisprojects.crete.gov.gr/geoserver/papyros/wfs', {
    headers: headers,
    method: 'POST',
    body: new XMLSerializer().serializeToString(featureRequest),
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      const features = new GeoJSON().readFeatures(json);
      if (features.length > 0) {
        source.addFeatures(features);
        if (type) {
          map.getView().fit(source.getExtent());
          map.getView().setZoom(map.getView().getZoom() - 1);
        }
      }
    });
}

// Modify draw layer
const modify = new Modify({ source: source });
map.addInteraction(modify);

let draw, snap; // global so we can remove them later
let typeSelect = 'Point';

const addInteractions = () => {
  draw = new Draw({
    source: source,
    type: typeSelect,
  });
  map.addInteraction(draw);
  snap = new Snap({ source: source });
  map.addInteraction(snap);
}


$('input[type="radio"').on('click', (e) => {
  typeSelect = e.target.value;
  map.removeInteraction(draw);
  map.removeInteraction(snap);
  try {
    map.removeInteraction(select);
  } catch (err) { }
  addInteractions();
})

// addInteractions();

// Draw from coordinates
$('#add').on('click', (e) => {

  if ($('#projection').val() == 'none' || $('#geometry_type').val() === 'none' || $('#coordinates').val().length === 0) {
    alert("Παρακαλώ επιλέξτε προβολικό σύστημα, τύπο γεωμετρίας και εισάγετε τις συντεταγμένες όπως στο παράδειγμα!");
  }

  try {
    const coords = $('#coordinates').val().split('\n');

    const projectedCoords = coords.map((e) => {
      const c = e.split(' ');
      if ($('#projection').val() === 'EPSG:4326') {
        return fromLonLat([c[0], c[1]]);
      } else if ($('#projection').val() === 'EPSG:2100') {
        const p = transform([Number(c[0]), Number(c[1])], 'EPSG:2100', 'EPSG:3857');
        return p;
      }
    })

    if ($('#geometry_type').val() === 'Point') {
      projectedCoords.forEach((e) => {
        const point = new Feature({
          geometry: new Point([e[0], e[1]]),
        });
        source.addFeature(point);
        map.getView().fit(point.getGeometry().getExtent());
      })
    } else if ($('#geometry_type').val() === 'LineString') {
      const line = new Feature({
        geometry: new LineString(projectedCoords),
      });
      source.addFeature(line);
      map.getView().fit(line.getGeometry().getExtent());
    } else if ($('#geometry_type').val() === 'Polygon' && projectedCoords.length > 2) {
      const line = new Feature({
        geometry: new Polygon([projectedCoords]),
      });
      source.addFeature(line);
      map.getView().fit(line.getGeometry().getExtent());
    }
  } catch (err) {
    alert("Λυπούμαστε, παρουσιάστηκε κάποιο σφάλμα... Παρακαλώ, επιλέξτε σωστό προβολικό σύστημα, τύπο γεωμετρίας και εισάγετε τις συντεταγμένες όπως στο παράδειγμα!");
  }
})

// Collapse
$('#collapse').on('click', (e) => {
  $('#card').css('display', 'none');
  $('#card-show').css('display', 'block');
})

// Expand
$('#expand').on('click', (e) => {
  $('#card').css('display', 'block');
  $('#card-show').css('display', 'none');
})

// Delete all
let select;
$('#delete').on('click', (e) => {
  select = new Select();

  $('input[type="radio"').filter('[value="Point"]').attr('checked', false);
  $('input[type="radio"').filter('[value="LineString"]').attr('checked', false);
  $('input[type="radio"').filter('[value="Polygon"]').attr('checked', false);

  map.removeInteraction(draw);
  map.removeInteraction(snap);
  map.removeInteraction(modify);
  map.addInteraction(select);

  select.on('select', function (e) {
    const features = e.target.getFeatures().getArray();

    var r = confirm("Η συγκεκριμένη γεωμετρία θα διαγραφεί για πάντα! Είστε σίγουροι;");
    if (r == true) {
      try {
        features.filter(f => f.id_ === undefined).forEach(f => {
          source.removeFeature(f);
        });
      } catch (err) { }

      transactWFS('delete', features.filter(f => f.id_ !== undefined)).then((response) => {
        if (response.status === 200) {
          try {
            features.filter(f => f.id_ !== undefined).forEach(f => {
              source.removeFeature(f);
            });
          } catch (err) { }
          getProject(false);
        }
        else {
          alert("Παρουσιάστηκε πρόβλημα κατά την διαγραφή...");
        }

      })
    }

    // map.addInteraction(draw);
    // map.addInteraction(snap);
    // map.addInteraction(modify);
    map.removeInteraction(select);

  })
})


// Save to geoserver
$('#save').on('click', (e) => {
  const features = source.getFeatures().map((f) => {
    f.values_['projectid'] = getParameterByName(config.projectURLparam);
    return f;
  })

  Promise.all([
    transactWFS('insert', features.filter(f => f.id_ === undefined)),
    transactWFS('update', features.filter(f => f.id_ !== undefined))
  ]).then((responses) => {
    if (responses[0].status === 200 && responses[1].status === 200)
      alert("Επιτυχής αποθήκευση!");
    else
      alert("Παρουσιάστηκε πρόβλημα κατά την αποθήκευση...");

    source.clear();
    getProject(false);
  })
})

const transactWFS = (mode, f) => {
  const formatWFS = new WFS();
  const formatGML = new GML({
    featureNS: 'https://papyros.gisprojects.crete.gov.gr',
    featureType: 'projects',
    srsName: 'EPSG:3857'
  });

  let node;

  switch (mode) {
    case 'insert':
      node = formatWFS.writeTransaction(f, null, null, formatGML);
      break;
    case 'update':
      node = formatWFS.writeTransaction(null, f, null, formatGML);
      break;
    case 'delete':
      node = formatWFS.writeTransaction(null, null, f, formatGML);
      break;
  }


  const headers = new Headers();
  headers.append('Authorization', 'Basic ' + btoa(auth));

  return fetch('https://gisprojects.crete.gov.gr/geoserver/papyros/wfs', {
    headers: headers,
    method: 'POST',
    body: new XMLSerializer().serializeToString(node),
  })

}


const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new bootstrap.Tooltip(tooltipTriggerEl)
})



