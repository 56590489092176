export default {
  projectURLparam: 'id',
  zoom: 9,
  lng: 24.941403,
  lat: 35.168420,
  minZoom: 8,
  maxZoom: 20,
  extent: [22.9607328, 34.7340785, 26.6850981, 35.6319899],
  googleOptions: {
    componentRestrictions: { country: "gr" }
  }
}